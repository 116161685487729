@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth !important;
    --line-color: #e5e7eb;
  }
  html.dark {
    background-color: black;
    --line-color: rgba(255, 255, 255, 0.2);
  }
  h1 {
    @apply text-4xl font-bold tracking-tight sm:text-5xl text-slate-900 dark:text-slate-200;
  }
  h2 {
    @apply text-3xl tracking-tight text-slate-900 sm:text-4xl dark:text-slate-300;
  }
  h3 {
    @apply mt-2 text-xl text-slate-900 dark:text-slate-300;
  }
  p {
    @apply text-lg tracking-tight text-slate-700 dark:text-slate-400;
  }
  label {
    @apply block mb-2 text-sm font-medium text-slate-700 dark:text-slate-400;
  }
  footer {
    @apply sticky top-[100vh] p-7 text-center block border-t-[1px] border-slate-400/10 dark:border-slate-200/10 dark:bg-black;
  }
}

@layer components {
  .hero-title {
    @apply mx-auto max-w-4xl font-medium sm:text-7xl dark:text-slate-900;
  }
  .main-menu-item {
    @apply inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-600 dark:hover:border-gray-600;
  }
  .mobile-main-menu-item {
    @apply block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-600 dark:hover:border-gray-600 dark:hover:bg-gray-800;
  }
  .mobile-main-menu-item-active {
    @apply bg-indigo-50 border-indigo-500 text-indigo-700 dark:bg-gray-800 dark:hover:bg-gray-700;
  }
  .input-normal {
    @apply w-full px-3 py-2 border rounded-md focus:outline-none border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-slate-800 dark:text-slate-300;
  }
  .input-error {
    @apply w-full px-3 py-2 border rounded-md focus:outline-none border-red-300 focus:ring-red-500 focus:border-red-500 dark:bg-slate-800 dark:text-slate-300 dark:border-red-500 dark:focus:ring-red-700 dark:focus:border-red-700;
  }
  .input-icon {
    @apply pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 dark:text-red-800;
  }
  .btn {
    @apply inline-flex items-center px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600 transition ease-in-out duration-150;
  }
  .borderBlack {
    @apply border border-black/10 dark:border-white/10;
  }
}

#main-menu a.active {
  @apply border-indigo-500 text-gray-800 dark:text-gray-200;
}
